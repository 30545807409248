$(document).ready(function(){
	// Using chosen plugin
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  document.cookie = "timzone=" + tz
  $("select:not(.no-chosen):not(#tenant_name2)").chosen({ width: '100%'});
	$("select:not(.no-chosen)").chosen();
	// Toggle select all checkox
	$('#select_all').click(function(e){
	  var table = $(e.target).closest('table');
	  $('tr td:first-child input:checkbox',table).prop('checked',this.checked);
	  var inpts = $(table).find('tbody>tr td:not(:first-child) input,select').prop('disabled', !this.checked);
	  var drops = $(table).find('tbody>tr td:not(:first-child) select').trigger("chosen:updated");
	});

  if ($("#dashboard_page").length > 0) {
    json_data = {
      "USER_ORDER": ["ALL", "PENDING", "REVIEW", "AWAITING_FULFILLMENT", "APPROVED", "PARTIALLY_FULFILLED", "FULFILLED", "FAILED"],
      "MERCHANT_PRODUCT": ["ALL", "PENDING",  "PLACED_AND_REVIEW", "AWAITING_CONFIRMATION_AND_CONFIRMED", "SHIPPED_AND_DELIVERED", "FAILED"],
      "MERCHANT_PRODUCT_2": ["ALL", "PENDING",  "PLACED_AND_REVIEW", "AWAITING_CONFIRMATION_AND_CONFIRMED", "APPROVED", "SHIPPED", "DELIVERED", "FAILED"]
    }

    for (var module_name in json_data) {
      if (json_data.hasOwnProperty(module_name)) {
        for (let i = 0; i < json_data[module_name].length; i++) {
          if ( $("#" + module_name + "_" + json_data[module_name][i]).length > 0) {
            $.ajax({
              url: "/admin/orders/count",
              type: "GET",
              data: {
                  module_name: module_name,
                  type: json_data[module_name][i]
              },
              success: function (response) {
                  $("#" + response.module_name + "_" + response.type).text(response.count)
              }
            })  
          }
        }
      }
    }
  }

});

// Flex init hide - seek according to boolean input select
$("#flex-init-id").hide();
$("#denomination-ids").show();
$("#variety_flex_init_amount_flag").change(function(){
	if($("#variety_flex_init_amount_flag").is(':checked')){
		$("#flex-init-id").slideDown();
		$("#denomination-ids").slideUp();
	}
	else{
		$("#flex-init-id").slideUp();
		$("#denomination-ids").slideDown();
	}
})

// Giftcard Style element repeater
if ($("#style-file").length) {
	$("#style-file").repeater({
		btnAddClass: 'btnadd',
		btnRemoveClass: 'btnremove',
		groupClass: 'r-group',
		minItems: 1,
		maxItems: 4,
		startingIndex: 0,
		reindexOnDelete: true,
		repeatMode: 'append',
		animation: null,
		animationSpeed: 400,
		animationEasing: 'swing',
		clearValues: true
	});
};

// Add index to styles form-data for proper generation of hash
$("#variety-manager").submit(function(){
	var groups = $(this).find("#style-file").find(".r-group");
  var position = 16;
  for (var i = 0; i < groups.length; i++) {
  	var inputs = $(groups[i]).find("input");
  	for (var k = 0; k < inputs.length; k++) {
  		original_name = $(inputs[k]).attr("name");
			final_name = [original_name.slice(0, position), i, original_name.slice(position)].join('');
  		$(inputs[k]).attr("name",final_name);
  	};
  };
})

$("#variety_primary_id").change(function(){
	primary_brand = $("#variety_primary_id :selected").text()
	$('#variety_secondary_ids option').each(function(){
    if (this.text == primary_brand) {
	    $(this).prop('disabled',true);
    }else{
    	$(this).prop('disabled',false);
    };
    $("#variety_secondary_ids").trigger("chosen:updated");
	});
})

// Toggle required attribute for flex init amount
$("#variety_flex_init_amount_flag").change(function(){
	if ($(this).is(":checked")) {
		$.each( $("#flex-init-id").find('input') , function( index, value ){
			$(value).prop("disabled",false)
	    $(value).prop("required",true)
		});
		$.each( $("#denomination-ids").find('input') , function( index, value ){
	    $(value).prop("disabled",true)
		});
	}else{
		$.each( $("#flex-init-id").find('input') , function( index, value ){
	    $(value).prop("disabled",true)
		});
		$.each( $("#denomination-ids").find('input') , function( index, value ){
			$(value).prop("disabled",false)
	    $(value).prop("required",true)
		});
	};
})

$('.nav-tabs').tabdrop({
	align: 'right',
	text: '<i class="fa fa-align-justify"></i> More '
});

//
$('.table').on('change', 'tbody tr td:first-child :checkbox', function () {
  var inpts = $(this).closest('tr').find('td:not(:first-child) input,select').prop('disabled', !this.checked);
  var drops = $(this).closest('tr').find('td:not(:first-child) select').trigger("chosen:updated");
})

// Datepicker for all dates with class `datepicker`
$('.datepicker').datepicker({
  todayBtn: "linked",
  keyboardNavigation: true,
  calendarWeeks: false,
  autoclose: true,
  clearBtn: true,
	format: 'yyyy-mm-dd'
})
.on('changeDate', function(e){
  from = e.format([],'yyyy-mm-dd')
  $(".date_to").datepicker('setStartDate',from)
  $(".date_to").datepicker('update')
});

$(function() {

  var start = moment().subtract(29, 'days');
  var end = moment();

  function cb(start, end) {
      $('#report_time span').html(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
  }

  $('#report_time').daterangepicker({

  	"opens": "left",
  	alwaysShowCalendars: true,
      startDate: start,
      endDate: end,
      ranges: {
         'Today': [moment(), moment()],
         'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
         'Last 7 Days': [moment().subtract(6, 'days'), moment()],
         'Last 30 Days': [moment().subtract(29, 'days'), moment()],
         'This Month': [moment().startOf('month'), moment().endOf('month')],
         'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
  }, cb);

  $('#report_time2').daterangepicker({

  	"opens": "left",
  	alwaysShowCalendars: true,
      startDate: start,
      endDate: end,
      ranges: {
         'Today': [moment(), moment()],
         'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
         'Last 7 Days': [moment().subtract(6, 'days'), moment()],
         'Last 30 Days': [moment().subtract(29, 'days'), moment()],
         'This Month': [moment().startOf('month'), moment().endOf('month')],
         'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
  }, cb);

  cb(start, end);

});

$("#same_as").click(function() {
    if (this.checked == true){
      $('#ope_addr').val($('#reg_addr').val()).prop('disabled', true);
      $('#ope_state').val($('#reg_state').val()).prop('disabled', true);
      $('#ope_city').val($('#reg_city').val()).prop('disabled', true);
      $('#ope_pin').val($('#reg_pin').val()).prop('disabled', true);
    } else{
      $('#ope_addr').prop('disabled', false);
      $('#ope_state').prop('disabled', false);
      $('#ope_city').prop('disabled', false);
      $('#ope_pin').prop('disabled', false);
    };
});

$(document).on("change", "#tenant_name2", function(){
  $.ajax({
    url: "/tenant_administrators/set_current_tenant",
    method: "PUT",
    data: {
      tenant_id: this.value
    }
  }).done(function(data){
    if(data.success == true) {
      window.location.reload();
    }
  });
});